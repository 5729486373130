import styled from 'styled-components';


export const Container = styled.div`
display:flex;

nav {
  display: flex;
   
    margin:0; 
    padding: 0;  
    position:fixed;
    width: 100%;
    height: 10vh;
    align-items:center;  
    align-items:center;          
    background: #005EB8;
    color:#fff;  
  
    
    
    }

    div {
      display: flex;
      align-content: space-between;   
    }  

`; 




export const LogoPjmei = styled.div` 
  width: 100%;
  margin-left: 3vh;

  img {
    
    height: 7vh;
    width:20vh;  
    margin-top:10px;
    
  }

`;

export const Contact = styled.div`
  margin-right: 3vh;

  img {
    height: 10vh;
    width:7vh;
    align-self: flex-end;

  }

`;



