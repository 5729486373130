import styled from 'styled-components';
 import { darken } from 'polished';
import Bacnkground from '../../../src/assets/images/background.png';

export const Wrapper = styled.div` 
  background: linear-gradient(-45deg,  rgb(0, 156, 59) 35%, rgb(255, 223, 0) 50%, rgb(0, 94, 184) 100% );
  align-content:center;
  align-items: center;
  

  
`;

export const Top = styled.div`

  display:flex;
  flex-wrap: wrap;
  align-items:center;
  align-content: space-around;
  padding: 0;
  background-image: url(${Bacnkground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;  
  width: auto;
  height: 90vh;
  text-align: center;

`;

export const Avatar = styled.div`    
    color:#fff;
    width: 20rem;
    height: 50rem;
    min-width:50%;    
    width: 90vh; 
    border-radius:5px;    
    padding:10px; 
    grid-gap:20px; 
    align-self: center;
    text-align: center;
    align-items:center;  

    div{
      display: flex;
      flex-wrap: wrap;
      margin-top: 10vh;
      
      >h1 {
        margin-top: 25rem;
        max-width: 80vh;
        width: 50vh;
        text-align: center;
      }
    } 
`;

export const TitleBeneficios=styled.div`
     text-align:center;
      width: 100%;
      margin-top: 26rem;
      font-size: 14px;
      align-content: center;
      align-items: center;
      text-transform:uppercase;
      border-radius: 5px;     
      
      >h1{
        align-content: center;
        margin: 20px 0 20px;
        color:#fff
      }
`;

export const StyledContact = styled.div`
    width: 100%;
    height: 100%;
    min-width:50%;
    width: 90vh; 
    border-radius:5px;    
    padding:5px; 
    grid-gap:20px; 
    align-self: center;
    text-align: center;
    align-items:center;  

    div{
      margin-top: 20vh;
    }

`;
export const Section1 = styled.div`
   
  display: grid;   
  justify-content:center;
  align-items: center; 
  left:15px;  
  grid-template-columns: repeat(3, 1fr);
  color: #000;    
  align-items:center;
  align-content: center;   /* max-width: 900px; */
  margin: 5px 0 5px;
  padding: 5rem;
  grid-gap: 2rem;

  
  li{
    display: flex;
    height: 200px;    
    grid-template-columns: repeat(3, 1fr);
    background:#fff;
    color: #000;
    text-align: left;
    align-content: space-between;    
    flex-direction: column;      
    border-radius: 8px;
    padding: 0.5rem;      
    width:300px;
      
    > strong {
        font-size: 22px;
        line-height: 20px;
        color: #000;
        margin-top: 5px;
        padding: 2vh;
      }
      aside{
      
      border:solid 1px #ddd;
      margin:0px;
    }
    > span {
      font-size: 16px;      
      margin: 5px 0 20px;
    }

    button{
      
      
      background: #fff;
      font-weight: bold;
      text-decoration:inherit;
      text-transform: uppercase;
      white-space: 30%;
      height: 40px;
      color: #009C3B;
      border: 0;
      border-radius: 4px;
      overflow: hidden;
      margin-top: auto;
      padding: 2rem;
      display: flex;
      align-items: center;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.06, '#005EB8')};
      }
      div{
        display:flex;
        height: 40px;
        background: none;     
       
        align-items: center;
        padding: 60px;
        
       
      }
    }   
 
  } 
  @media (max-width: 900px){
    grid-template-columns:  1fr 1fr;
  }

  @media (max-width: 500px){
    grid-template-columns:  1fr;
  }

  


  

`;

export const Section2 = styled(Section1)`

li{
    background: #005EB8;
    height: 330px; 
    strong{
      color:#fff
    }
    span{
      color:#fff;
    }
    button {
      
      div {
        strong{
          color:#009C3B
        }
      }
    }
   
  }
`;



export const TitleServicos=styled.div`
     text-align:center;
      width: 100%;
      margin-top: 1rem;
      font-size: 20;
      align-content: center;
      align-items: center;
      text-transform:uppercase;
      border-radius: 5px;     
      
      >h1{
        align-content: center;
        margin: 20px 0 20px;
        color:#fff
      }
`;


export const Hindi = styled.div`
  display: grid;
  align-items: center;
  align-content: space-between;
  justify-content:center;
  align-items: center; 
  left:15px;  
  grid-template-columns: repeat(3, 1fr);
  color: #fff;    
  align-items:center;
  align-content: center;   /* max-width: 900px; */
  margin: 5px 0 5px;
  padding: 5rem;
  grid-gap: 2rem;
  text-align: center;
  background: #005EB8;//linear-gradient(-45deg,  rgb(0, 156, 59) 35%, rgb(255, 223, 0)  100% );

  div{
    max-width: 20rem;
    text-align: center;
    display: grid;
    align-items: center;
    align-content: space-between;
    justify-content:center;
    align-items: center;   
    grid-template-columns:1fr;
    strong {      
        font-size: 18px;
        line-height: 20px;
        color: #fff;
        margin-top: 5px;
        text-align:center;        
       
      }

      div {
        text-align: center;
        display: block;
        padding: 10px;

        strong {
          text-align: center;
        }
        span {
          text-align: center;
        }
      }
 

    span {
      padding: 2px;
      margin-top: 5px;
      font-size: 14px;      
      margin: 5px 0 20px;
      text-align: center;
    }

   

    }
    @media (max-width: 900px){
    grid-template-columns:  1fr 1fr;
   }

  @media (max-width: 500px){
    grid-template-columns:  1fr;
  }

`;

export const LinksStyle = styled.div` 
    text-decoration: none;
  
  color: inherit;;
  
  text-transform: uppercase;
  color: #fff;
    &:hover {
  color: ${darken(0.01, '#fff')}; 
  .active {
        cursor: pointer; 
        border-bottom: 1px solid white;
        margin: 0 1rem;
        padding: 1rem;  
      } 
} 
`;





