import styled from 'styled-components';
// import { transparentize } from 'polished';

export const Wrapper = styled.div` 
  background: image;  
  float: center; 
  margin-top: 50px;
  padding: 0px;
  
  
 
`;

export const Content = styled.div` 
  justify-content:right;
  align-items: center;    
  padding: 10px;
  
  div {
      color:#fff;
      margin-top: 5px;
      align-items: center;
      display:grid;
      text-align:center;
      justify-content:center; 
      
  }    
 
`;

export const Forms = styled.div`  
       
  display:grid;
  justify-content:space-between;
  width: 100%;         
  align-items: center;
      
       form{
        display:grid;
        justify-content: center;
        align-items: center;
         
        input{
          
          padding: 10px;
          grid-gap: 10px;
          width: max-content;
          height: max-content;
          border-radius: 4px; 
          border: none;
          align-content: space-between;
          align-items: center;
          margin: 8px 0 10px;
        }

        > textarea{
          width: 100%; 
          max-width:201px;        
          height: 130px;         
          border: none;
          padding:5px;
          
          margin-right: 10px;
          margin: 5px 0px 5px;
          border-radius: 4px;
        }
        
        
         
        >button {
          background: #009C3B;                 
          display: flex;
          justify-content:center;  
          color: #fff;
          font-weight: bold;
          border:none;
          border-radius: 4px;      
          padding: 20px;
          align-items:center;
          text-transform: uppercase;
          margin: 5px 0 5px;

          &:disabled {
            color: #FFDF00;
            opacity: 0.6;
          }

          &:not(:disabled):hover {
            background: darken(0.1, #009C3B)
          }
        }
      }

       
`;

