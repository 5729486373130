import React from 'react';
import { Route } from 'react-router-dom';


export default function RouteWrapper() {

  return (
    <Route
    />
  );

}