import { createGlobalStyle } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle` 
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  
}
*:focus{
  outline: 0;
}
html, body, #root {
  height:100vh;
  
body { 
  -webkit-font-smoothing: antialiased;
  
}
body, input, button {
  font: 14px 'Roboto', sans-serif;
  
}
#root{
    
    max-width: auto;
    margin: 0, auto;
    padding: 0px 0px 0px;

  }

button {
  cursor: pointer;
}
}
`;