import React, { useState, useEffect} from 'react';
import {useHistory, Link} from "react-router-dom"
import { Wrapper, Section1,Section2, LinksStyle, TitleBeneficios, TitleServicos,Avatar,Top,  StyledContact, Hindi } from './styles';



import Contact from '../../components/Contact';




export default function Home() {
  const [posts, setPosts] = useState([]);
  const [services, setServices] = useState([]);

  const history = useHistory();

  useEffect(() => {
    fetch('./posts.json',{
      headers:{
        Accept:"application/json"
      }
    }).then(res => res.json())
      .then(res => setPosts(res.data))
  }, []);

  useEffect(() => {
    fetch('./services.json',{
      headers:{
        Accept:"application/json"
      }
    }).then(res => res.json())
      .then(res => setServices(res.data))
  }, []);




  const landpage = {
    post: [
      {
        date: new Date(),
        email:
          "contato@pjmei.com.br"
      },{         
        Horario: "Seg à Sex - 8h às 11h30 e das 13 às 17:30",
        Sobre: "Realizamos a inscrição, regularização, baixa e manutenção dos registros do MEI lembrando que somos prestadores de serviço e caso o cliente deseje pode realizar ele mesmo este serviço no site do governo."
      }
    ]
  }

  return (
    <Wrapper>
        <Top >
          <Avatar>
            <div>             
              <h1>Está com dificuldades para conseguir seu CNPJ Mei?</h1>          
            </div>        
          </Avatar>
          <StyledContact >
            <Contact/>
          </StyledContact>
        </Top>

      <TitleBeneficios>
        <h1>Quais as vantagens de ter um CNPJ Mei.</h1>
      </TitleBeneficios>

      
      <Section1>        
         {posts.map(post =>(
            <li key={post.id}> 
            <strong>{post.author.name}</strong>
            <aside />
            <span>{post.content}</span>    
            </li>
            
         ))}
        </Section1>

      <TitleServicos>
        <h1>Podemos te ajudar com estes serviços:</h1>
      </TitleServicos>

      <Section2>
        { services.map(service =>(
          <li key={service.id}>
          <strong>{service.author.name}</strong>
          <aside/>
          <span>{service.content}</span>

          
          <button type='button'
          onClick={() => history.push(service.author.link)}
          ><div>
            <strong>Saiba Mais</strong>
            </div></button>
          
          </li>
        ))} 

      </Section2>

      <Hindi>
        
        <div>  
          <div>
          <strong>Contato: </strong>
          
          <span>{landpage.post[0].email}</span>
            </div>      
          
          <div>
          <strong>Horário: </strong>
           <span>Funcionamos de Seg. à sexta de 08:00 às 17:30hs.</span>
          </div>
          
        </div>
        <div>        
          <strong>Sobre nós: </strong>
          <aside/>
          <span>A PJMEI é uma empresa criada com o intuido de auxiliar pessoas que desejam empreender de forma correta,
             seguindo todas as boas praticas da legislação brasileira, acreditamos que assim pode se ter uma estrutura 
             de negócio organizada e com visão de crescimento a longo prazo. Vamos juntos!</span>         
        </div>

        <div>  
          <strong>Links: </strong>
          <Link to='https://pjmei.com.br/policies/privacy-policy'>
            <LinksStyle> Politica de Privacidade </LinksStyle></Link>
          <Link to='https://pjmei.com.br/policies/terms-policy'>
            <LinksStyle> Termo de Serviço</LinksStyle></Link>
          <strong>Registro: </strong>
          <aside/>          
          <span>CNPJ: 42.565.137/0001-33</span>
          <span>@PJMEI-2023 </span>
        </div>
        
        {/* <p>{landpage.post[0].Tecnologias} </p> */}
        
      </Hindi>
    </Wrapper >
  );
}

