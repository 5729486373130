import React, { useState } from 'react';
import { Content, Wrapper,Forms } from './styles';
import emailjs from '@emailjs/browser';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

export default function Contact() {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const schema = Yup.object().shape({

    email: Yup
    .string()
    .email('Email Obrigadório!')
    .required('O e-mail é obrigatório!'),

    phone: Yup
    .string()   
    .oneOf([Yup.ref('phone'), null],'Campo obrigatório') 
    .required('Obrigatório informar o telefone.')
    .min(9, 'O numero está invalido.'),

    fullName: Yup
    .string()
    .required('Nome completo obrigatório.'),    

    message: Yup
    .string()
    .required('Mensagem obrigatória.'),  
    
    
    
    
  });


  const customId = "custom-id-yes";

  
  function sendEmail(e, {reset}){   

  setLoading(true);

    if(name !=='' || email !=='' || phone!==''|| message!==''){
     
      
    const templateParams = {
      from_name: name,
      message: message,
      phone_number:phoneMask(phone),
      email: email
    }
   
    emailjs.send("service_vxwz0sr","template_5jqwdfn",templateParams,"HiN6fVyHfFvpz3BfS")    
    .then(()=>{
      toast.success('ENVIADO',{
        toastId: customId,
        position: toast.POSITION.BOTTOM_CENTER
      })
   
     setName('')
     setEmail('')
     setMessage('')
     setPhone('')
     setLoading(false);   
     reset(e); 
    
    },()=>{
      toast.error('Erro no envio!',{
        toastId: customId,
        position: toast.POSITION.BOTTOM_CENTER   
      })
    })
    
    
  } 
  

  } 

  const phoneMask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{2})(\d)/,"($1) $2")
    value = value.replace(/(\d)(\d{4})$/,"$1-$2")
    return value
  }

  return (
    <Wrapper>
      <Content>
       
      
    <div className="container">
      <h1 className="title">FALE COM NOSSOS ANALISTAS.</h1>
      <span>Faremos toda a assessoria para cadastro do seu CNPJ, você recebera todas as informações por e-mail.</span>

<Forms>
      <Form className="form" schema={schema} onSubmit={sendEmail} >
        
          <Input 
            name="fullName"            
            type="text"
            placeholder="*Nome completo"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          
          <Input 
            name="email"         
            type="text"
            placeholder="*e-mail"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <Input 
            name="phone"         
            type="text"
            maxLength="15"
            placeholder="*99 999999-9999"
            onChange={(e) => setPhone(phoneMask(e.target.value))}
            value={phone}
          />  
        
          <Input multiline id='textarea'
            name="message"         
            type="text"
            maxLength="320"
            
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />  
           
           
          <button 
          type="submit" 
          disabled={loading}           
          >
            {loading ? 'Enviando...' : 'Enviar'}</button>       
        </Form>
    
      </Forms>
    </div>
    </Content>
    </Wrapper>
  );
}


