import React from 'react';
import whatsapp from '../../assets/images/whats.svg'
import Logo from '../../assets/images/logoPjmei.png';


import { Container, Contact, LogoPjmei } from './styles';


function Header() {
  return (
    <Container>      
      <nav>
       
          <LogoPjmei>
            <img alt='logo'src={Logo}  />
          </LogoPjmei>
          <Contact>
            <a href="https://api.whatsapp.com/send?phone=553133781908">
              <img src={whatsapp} alt="whatapp" color="#fff" />
            </a>
          </Contact>
        
      </nav>
    </Container>
  )
}
export default Header;